.menu_txt {
  margin-left: 10px;
}
.divider-10 {
  width:100%; 
  min-height:1px; 
  margin-top:10px; 
  margin-bottom:10px;  
  display:inline-block; 
  position:relative;
}
.menu-text-highlight {
  font-size: 0.8rem !important;
  font-weight: 800;
}

.logo-image {
  margin-top: 4px;
  margin-left: 75px;
  width: 56%;
  height: 93%;
  text-align: center;
  object-fit: cover;
  color: transparent;
  text-indent: 10000px;
}
.ellipsis-text {
  display: inline-block;
  width: 100px !important;
  white-space: nowrap; 
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}
.display-text {
  display: block;
  max-width: 80% !important;
  word-break: break-all;
}
.pre-wrap-text {
  display: block;
  white-space: pre-wrap!important;
  word-break: break-all;
}
.break_hash {
  overflow-wrap: break-word;
}
.long-text {
  padding: 2px;
  font-size: 12px;
  font-weight: 400;
  direction: ltr;
  width: 100%;
  margin: auto;
  text-align: justify;
  word-break: break-word;
  white-space: pre-line;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  color: red !important;
}
.search-addon {
  cursor:pointer;
}
.search-addon:hover { 
  color: #0275d8; 
  background-color: #CFD8DC;
} 
.search-field {
  font-size: 0.6rem !important;
  font-weight: 500;
}
.gitNote {
  font-size: 0.6rem !important;
  font-weight: 500;
}
.gitInputDetail {
  font-size: 0.6rem !important;
}
.app-card-image {
  width: 100%;
  height: auto;
  max-width: 100%; /* Ensure the image does not exceed the card width */
  max-height: 100%; /* Ensure the image does not exceed the card height */
  display: block; /* Remove extra space below the image */
}
.added {
  background-color: #0ba00b!important;
}

.removed {
  background-color: #f23f3f!important;
}
.monaco-editor .added {
  background-color: #0ba00b!important;/* Light green */
}

.monaco-editor .removed {
  background-color: #f23f3f!important; /* Light red */
}

.source-node {
  /* Style for folder nodes */
  cursor: default;  /* Default cursor for folders */
  padding: 5px 0;  /* Add some padding for visual spacing */
}

.source-file {
  /* Style for file nodes */
  cursor: pointer;  /* Pointer cursor to indicate clickability */
  padding: 5px 0;  /* Consistent padding with folder nodes */
  color: blue;  /* Optional: color files differently for better distinction */
}


.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 1px;
  margin: 0 -5px;
  background: #000;
  border-right: 5px solid rgba(255, 255, 255, 0) !important;
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}


.dynamic-grid-container {
  overflow: auto;
  /* Responsive adjustments */
  @media (max-width: 600px) {
      overflow: visible;
  }
}